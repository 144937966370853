import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import industries from '../../config/industries.js';
import categories from '../../config/categories.js';
import workTimes from '../../config/work-times.js';
import employmentTypes from '../../config/employment-types.js';

import css from './ListingPage.module.css';

const SectionDetailsMaybe = props => {
  const { title, publicData } = props;

  if (!publicData) {
    return null;
  }

  const industry = industries.find(item => item.key === publicData.industry)?.label;
  const category = categories.find(item => item.key === publicData.category)?.label;
  const address = publicData.location && publicData.location.address;
  const hiringManager = publicData.hiringManager;
  const employmentType = employmentTypes.find(item => item.key === publicData.employmentType)
    ?.label;
  const workTime = workTimes.find(item => item.key === publicData.workTime)?.label;
  const officeHours = publicData.officeHours;
  const requireTravelling = publicData.requireTravelling;
  const remoteWork = publicData.remoteWork;
  const expectedStartingTime = publicData.expectedStartingTime;
  const positionsNumber = publicData.positionsNumber;

  return (
    <div className={css.sectionDetails}>
      <h2 className={css.detailsTitle}>
        {positionsNumber ? (
          <FormattedMessage
            id="ListingPage.titleWithPositions"
            values={{ title, count: positionsNumber }}
          />
        ) : (
          title
        )}
      </h2>
      <div className={css.mainInformation}>
        <div className={css.mainInfo1}>
          <ul className={css.details}>
            <li className={css.detailsRow}>
              <span className={css.detailLabel}>
                <FormattedMessage id="ListingPage.industry" />
              </span>
              <span className={css.detailText}>{industry}</span>
            </li>
            <li className={css.detailsRow}>
              <span className={css.detailLabel}>
                <FormattedMessage id="ListingPage.category" />
              </span>
              <span className={css.detailText}>{category}</span>
            </li>
            <li className={css.detailsRow}>
              <span className={css.detailLabel}>
                <FormattedMessage id="ListingPage.address" />
              </span>
              <span className={css.detailText}>{address}</span>
            </li>
            <li className={css.detailsRow}>
              <span className={css.detailLabel}>
                <FormattedMessage id="ListingPage.hiringManager" />
              </span>
              <span className={css.detailText}>{hiringManager}</span>
            </li>
            <li className={css.detailsRow}>
              <span className={css.detailLabel}>
                <FormattedMessage id="ListingPage.employmentType" />
              </span>
              <span className={css.detailText}>{employmentType}</span>
            </li>
          </ul>
        </div>
        <div className={css.mainInfo2}>
          <ul className={css.details}>
            <li className={css.detailsRow}>
              <span className={css.detailLabel}>
                <FormattedMessage id="ListingPage.workTime" />
              </span>
              <span className={css.detailText}>{workTime}</span>
            </li>
            <li className={css.detailsRow}>
              <span className={css.detailLabel}>
                <FormattedMessage id="ListingPage.officeHours" />
              </span>
              <span className={css.detailText}>{officeHours}</span>
            </li>
            <li className={css.detailsRow}>
              <span className={css.detailLabel}>
                <FormattedMessage id="ListingPage.requireTravelling" />
              </span>
              <span className={css.detailText}>{requireTravelling}</span>
            </li>
            <li className={css.detailsRow}>
              <span className={css.detailLabel}>
                <FormattedMessage id="ListingPage.remoteWork" />
              </span>
              <span className={css.detailText}>{remoteWork}</span>
            </li>
            <li className={css.detailsRow}>
              <span className={css.detailLabel}>
                <FormattedMessage id="ListingPage.expectedStartingTime" />
              </span>
              <span className={css.detailText}>{expectedStartingTime}</span>
            </li>
            <li className={css.detailsRow}>
              <span className={css.detailLabel}>
                <FormattedMessage id="ListingPage.positionsNumber" />
              </span>
              <span className={css.detailText}>{positionsNumber}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SectionDetailsMaybe;

import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { propTypes } from '../../../util/types';
import { ensureCurrentUser } from '../../../util/data';
import config from '../../../config';
import { formatMoney } from '../../../util/currency';
import { types as sdkTypes } from '../../../util/sdkLoader';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  IconEnquiry,
  FieldCurrencyInput,
  FieldCheckbox,
} from '../../../components';

import css from './ProposalForm.module.css';

const { Money } = sdkTypes;

const ProposalFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        autoFocus,
        rootClassName,
        className,
        submitButtonWrapperClassName,
        formId,
        handleSubmit,
        inProgress,
        intl,
        listingTitle,
        // eslint-disable-next-line no-unused-vars
        authorDisplayName,
        currentUser,
        sendProposalError,
        spendTokenError,
        values,
      } = fieldRenderProps;

      const user = ensureCurrentUser(currentUser);
      const privateData = user.attributes.profile.privateData || {};
      const currentTokensNumber = privateData.tokens || '';
      const notEnoughTokens = currentTokensNumber < 1;

      const verifyToken = values && values.verifyToken;
      const isChecked = verifyToken && !isEmpty(verifyToken);

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'ProposalForm.priceRequired',
        })
      );
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'ProposalForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      const recruitmentEstimatedTimeLabel = intl.formatMessage({
        id: 'ProposalForm.recruitmentEstimatedTimeLabel',
      });
      // eslint-disable-next-line no-unused-vars
      const recruitmentEstimatedTimePlaceholder = intl.formatMessage({
        id: 'ProposalForm.recruitmentEstimatedTimePlaceholder',
      });
      const recruitmentEstimatedTimeRequiredMessage = intl.formatMessage({
        id: 'ProposalForm.recruitmentEstimatedTimeRequired',
      });
      const recruitmentEstimatedTimeRequired = validators.requiredAndNonEmptyString(
        recruitmentEstimatedTimeRequiredMessage
      );

      const whyShouldYouBeChoosenLabel = intl.formatMessage({
        id: 'ProposalForm.whyShouldYouBeChoosenLabel',
      });
      // eslint-disable-next-line no-unused-vars
      const whyShouldYouBeChoosenPlaceholder = intl.formatMessage({
        id: 'ProposalForm.whyShouldYouBeChoosenPlaceholder',
      });
      const whyShouldYouBeChoosenRequiredMessage = intl.formatMessage({
        id: 'ProposalForm.whyShouldYouBeChoosenRequired',
      });
      const whyShouldYouBeChoosenRequired = validators.requiredAndNonEmptyString(
        whyShouldYouBeChoosenRequiredMessage
      );

      const additionalResourcesUsedLabel = intl.formatMessage({
        id: 'ProposalForm.additionalResourcesUsedLabel',
      });
      const additionalResourcesUsedPlaceholder = intl.formatMessage({
        id: 'ProposalForm.additionalResourcesUsedPlaceholder',
      });

      const industryExperienceLabel = intl.formatMessage({
        id: 'ProposalForm.industryExperienceLabel',
      });
      // eslint-disable-next-line no-unused-vars
      const industryExperiencePlaceholder = intl.formatMessage({
        id: 'ProposalForm.industryExperiencePlaceholder',
      });

      const publicReferencesLabel = intl.formatMessage({
        id: 'ProposalForm.publicReferencesLabel',
      });
      // eslint-disable-next-line no-unused-vars
      const publicReferencesPlaceholder = intl.formatMessage({
        id: 'ProposalForm.publicReferencesPlaceholder',
      });

      const otherAreasOfConsiderationLabel = intl.formatMessage({
        id: 'ProposalForm.otherAreasOfConsiderationLabel',
      });
      // eslint-disable-next-line no-unused-vars
      const otherAreasOfConsiderationPlaceholder = intl.formatMessage({
        id: 'ProposalForm.otherAreasOfConsiderationPlaceholder',
      });

      const serviceGuaranteeLabel = intl.formatMessage({
        id: 'ProposalForm.serviceGuaranteeLabel',
      });
      // eslint-disable-next-line no-unused-vars
      const serviceGuaranteePlaceholder = intl.formatMessage({
        id: 'ProposalForm.serviceGuaranteePlaceholder',
      });

      const verifyTokenLabel = intl.formatMessage({
        id: 'ProposalForm.verifyTokenLabel',
      });

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress || !isChecked || notEnoughTokens;

      return (
        <Form className={classes} onSubmit={handleSubmit} enforcePagePreloadFor="OrderDetailsPage">
          <IconEnquiry className={css.icon} />
          <h2 className={css.heading}>
            <FormattedMessage id="ProposalForm.heading" values={{ listingTitle }} />
          </h2>
          <FieldCurrencyInput
            className={css.input}
            name="totalPrice"
            id={formId ? `${formId}.totalPrice` : 'totalPrice'}
            autoFocus={autoFocus}
            label={intl.formatMessage({ id: 'ProposalForm.pricePerTender' })}
            placeholder={intl.formatMessage({ id: 'ProposalForm.priceInputPlaceholder' })}
            currencyConfig={config.currencyConfig}
            validate={priceValidators}
          />
          <FieldTextInput
            className={css.field}
            type="text"
            name="recruitmentEstimatedTime"
            id={formId ? `${formId}.recruitmentEstimatedTime` : 'recruitmentEstimatedTime'}
            label={recruitmentEstimatedTimeLabel}
            // placeholder={recruitmentEstimatedTimePlaceholder}
            validate={recruitmentEstimatedTimeRequired}
          />
          <FieldTextInput
            className={css.field}
            type="text"
            name="additionalResourcesUsed"
            id={formId ? `${formId}.additionalResourcesUsed` : 'additionalResourcesUsed'}
            label={additionalResourcesUsedLabel}
            placeholder={additionalResourcesUsedPlaceholder}
          />
          <FieldTextInput
            className={css.field}
            type="textarea"
            name="whyShouldYouBeChoosen"
            id={formId ? `${formId}.whyShouldYouBeChoosen` : 'whyShouldYouBeChoosen'}
            label={whyShouldYouBeChoosenLabel}
            // placeholder={whyShouldYouBeChoosenPlaceholder}
            validate={whyShouldYouBeChoosenRequired}
          />
          <FieldTextInput
            className={css.field}
            type="textarea"
            name="industryExperience"
            id={formId ? `${formId}.industryExperience` : 'industryExperience'}
            label={industryExperienceLabel}
            // placeholder={industryExperiencePlaceholder}
          />
          <FieldTextInput
            className={css.field}
            type="textarea"
            name="publicReferences"
            id={formId ? `${formId}.publicReferences` : 'publicReferences'}
            label={publicReferencesLabel}
            // placeholder={publicReferencesPlaceholder}
          />
          <FieldTextInput
            className={css.field}
            type="textarea"
            name="otherAreasOfConsideration"
            id={formId ? `${formId}.otherAreasOfConsideration` : 'otherAreasOfConsideration'}
            label={otherAreasOfConsiderationLabel}
            // placeholder={otherAreasOfConsiderationPlaceholder}
          />
          <FieldTextInput
            className={css.field}
            type="textarea"
            name="serviceGuarantee"
            id={formId ? `${formId}.serviceGuarantee` : 'serviceGuarantee'}
            label={serviceGuaranteeLabel}
            placeholder={serviceGuaranteePlaceholder}
          />
          <FieldCheckbox
            className={css.sameAddress}
            id="verifyToken"
            name="verifyToken"
            label={verifyTokenLabel}
            value="verifyToken"
            useSuccessColor
          />
          <h4>
            <p>{intl.formatMessage({ id: 'ProposalForm.withdrawProposalNotice' })}</p>
            <p>{intl.formatMessage({ id: 'ProposalForm.cvNotice' })}</p>
          </h4>
          <div className={submitButtonWrapperClassName}>
            {sendProposalError ? (
              <p className={css.error}>
                <FormattedMessage id="ProposalForm.sendProposalError" />
              </p>
            ) : null}
            {!inProgress && (spendTokenError || notEnoughTokens) ? (
              <p className={css.error}>
                <FormattedMessage id="ProposalForm.spendTokenError" />
              </p>
            ) : null}
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="ProposalForm.submitButtonText" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

ProposalFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendProposalError: null,
  spendTokenError: null,
};

ProposalFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  authorDisplayName: string.isRequired,
  sendProposalError: propTypes.error,
  spendTokenError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ProposalForm = compose(injectIntl)(ProposalFormComponent);

ProposalForm.displayName = 'ProposalForm';

export default ProposalForm;
